import { render, staticRenderFns } from "./ValidationError.vue?vue&type=template&id=5b06fb5c&scoped=true&"
import script from "./ValidationError.js?vue&type=script&lang=js&"
export * from "./ValidationError.js?vue&type=script&lang=js&"
import style0 from "./style.css?vue&type=style&index=0&id=5b06fb5c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b06fb5c",
  null
  
)

/* custom blocks */
import block0 from "./ValidationError.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fsrc%2Fcomponents%2Fcommon%2Fform%2FValidationError%2FValidationError.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports