import { render, staticRenderFns } from "./SidebarMenu.vue?vue&type=template&id=2b837e11&scoped=true&"
import script from "./SidebarMenu.js?vue&type=script&lang=js&"
export * from "./SidebarMenu.js?vue&type=script&lang=js&"
import style0 from "./SidebarMenu.scss?vue&type=style&index=0&id=2b837e11&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b837e11",
  null
  
)

/* custom blocks */
import block0 from "./SidebarMenu.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fsrc%2Fcomponents%2Fuseraccount%2FSidebarMenu%2FSidebarMenu.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports