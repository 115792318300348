import { render, staticRenderFns } from "./OrderOverview.vue?vue&type=template&id=1193e0c5&scoped=true&"
import script from "./OrderOverview.js?vue&type=script&lang=js&"
export * from "./OrderOverview.js?vue&type=script&lang=js&"
import style0 from "./OrderOverview.scss?vue&type=style&index=0&id=1193e0c5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1193e0c5",
  null
  
)

/* custom blocks */
import block0 from "./OrderOverview.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fsrc%2Fcomponents%2Fcheckout%2FOrderOverview%2FOrderOverview.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports