import { render, staticRenderFns } from "./BillingDetails.vue?vue&type=template&id=3a36e214&"
import script from "./BillingDetails.js?vue&type=script&lang=js&"
export * from "./BillingDetails.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./BillingDetails.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fsrc%2Fcomponents%2Fcheckout%2FBillingDetails%2FBillingDetails.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports