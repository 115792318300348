import { render, staticRenderFns } from "./Adyen.vue?vue&type=template&id=6d1e81a8&"
import script from "./Adyen.js?vue&type=script&lang=js&"
export * from "./Adyen.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Adyen.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fsrc%2Fcomponents%2Fcheckout%2FPaymentMethod%2FAdyen%2FAdyen.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports