import { render, staticRenderFns } from "./FooterMarketing.vue?vue&type=template&id=7ba014da&"
import script from "./FooterMarketing.js?vue&type=script&lang=js&"
export * from "./FooterMarketing.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./FooterMarketing.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fsrc%2Fcomponents%2Ffooter%2FFooterMarketing%2FFooterMarketing.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports