import { render, staticRenderFns } from "./NewsletterSubscriptionForm.vue?vue&type=template&id=05b3e45b&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./NewsletterSubscriptionForm.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fsrc%2Fcomponents%2Ffooter%2FNewsletterSubscriptionForm%2FNewsletterSubscriptionForm.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports