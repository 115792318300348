import { render, staticRenderFns } from "./PageProductDetail.vue?vue&type=template&id=4cd146a4&"
import script from "./PageProductDetail.js?vue&type=script&lang=js&"
export * from "./PageProductDetail.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./PageProductDetail.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fsrc%2Fcomponents%2Fproductdetail%2FPageProductDetail%2FPageProductDetail.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports