import { render, staticRenderFns } from "./ProductInfo.vue?vue&type=template&id=c903051c&scoped=true&"
import script from "./ProductInfo.js?vue&type=script&lang=js&"
export * from "./ProductInfo.js?vue&type=script&lang=js&"
import style0 from "./ProductInfo.scss?vue&type=style&index=0&id=c903051c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c903051c",
  null
  
)

/* custom blocks */
import block0 from "./ProductInfo.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fsrc%2Fcomponents%2Fproductdetail%2FProductInfo%2FProductInfo.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports