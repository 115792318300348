import { render, staticRenderFns } from "./TopBar.vue?vue&type=template&id=b20c5010&scoped=true&"
import script from "./TopBar.js?vue&type=script&lang=js&"
export * from "./TopBar.js?vue&type=script&lang=js&"
import style0 from "./TopBar.scss?vue&type=style&index=0&id=b20c5010&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b20c5010",
  null
  
)

/* custom blocks */
import block0 from "./TopBar.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fsrc%2Fcomponents%2Fproductoverview%2FTopBar%2FTopBar.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports